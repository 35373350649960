export const query = `query getStoreMetaData($withPickupOnly: Boolean!) {
  storeInfo {
    isPremium
    canStoreShip
    hasCreatedPaymentMethods
  }
  shipping {
    isPickupOnly @include(if: $withPickupOnly)
  }
}`;
